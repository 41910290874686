import * as React from "react";

import { HeroSection } from "../../components";

import * as Styled from "./Home.styled";

export const Home: React.FC = () => {
  const eventsRef = React.useRef<null | HTMLDivElement>(null);

  const handleWatchNowClick = React.useCallback(() => {
    if (eventsRef && eventsRef.current) {
      console.log(eventsRef.current.offsetTop);
      window.scrollTo({
        behavior: "smooth",
        top: eventsRef.current.offsetTop,
      });
    }
  }, []);

  return (
    <>
      <HeroSection onWatchNowClick={handleWatchNowClick} />

      <Styled.EventsContainer id="events" ref={eventsRef}>
        <h1>Featured Events</h1>

        <div id="cemboo-stream-library"></div>
      </Styled.EventsContainer>
    </>
  );
};
